<template>
  <div id="viewport">
    <svg
      aria-hidden="true"
      focusable="false"
      data-prefix="fas"
      data-icon="desktop"
      class="svg-inline--fa fa-desktop fa-w-18"
      role="img"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 576 512"
      :style="{ transform: `translateY(-${translateY * 1.5}rem)` }"
    >
      <path
        fill="currentColor"
        d="M528 0H48C21.5 0 0 21.5 0 48v320c0 26.5 21.5 48 48 48h192l-16 48h-72c-13.3 0-24 10.7-24 24s10.7 24 24 24h272c13.3 0 24-10.7 24-24s-10.7-24-24-24h-72l-16-48h192c26.5 0 48-21.5 48-48V48c0-26.5-21.5-48-48-48zm-16 352H64V64h448v288z"
      ></path>
    </svg>
    <div class="wrapper">
      <p :style="{ transform: `translateY(-${translateY}rem)` }">
        Используйте вертикальную ориентацию устройства или увеличьте размер
        браузера.
      </p>
      <div class="shadow" :style="{ transform: `scale(${scale})` }"></div>
    </div>
  </div>
</template>

<script>
import { ref, onMounted, onBeforeUnmount } from "vue";
import { animate } from "@/js/utils/_animate";

export default {
  setup() {
    const translateY = ref(0),
      scale = ref(1);

    let timer;

    onMounted(() => {
      const move = () =>
        animate({
          duration: 1000,
          timing(timeFraction) {
            return timeFraction;
          },
          draw(progress) {
            if (progress < 0.5) {
              translateY.value = progress * 3;
              scale.value = 1 - progress / 3;
            } else {
              translateY.value = (1 - progress) * 3;
              scale.value = 1 - (1 - progress) / 3;
            }
          }
        });

      move();

      timer = setInterval(() => {
        move();
      }, 1000);
    });

    onBeforeUnmount(() => {
      clearInterval(timer);
    });

    return {
      translateY,
      scale
    };
  }
};
</script>

<style lang="scss" scoped>
#viewport {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: $background_yellow;
  z-index: 999;
}

svg {
  width: 6rem;
}

p {
  text-transform: uppercase;
  font-weight: 900;
  margin-top: 2rem;
  max-width: 25rem;
  text-align: center;
  font-size: 1.2rem;
}

.shadow {
  width: 5rem;
  height: 2rem;
  border-radius: 50%;
  background-color: $color_black;
  margin-left: calc(50% - 2.5rem);
  margin-top: 2rem;
}

@include --tablet {
  svg {
    width: 4rem;
  }

  p {
    font-size: 1rem;
    margin-top: 1rem;
    line-height: 1.4rem;
  }
}
</style>

<template>
  <div id="preloader" :class="{ _up: progress >= 100 }">
    <div class="preloader_content">
      <div class="preloader_logo">
        <Logo />
      </div>
      <div class="preloader_progress" :style="{ width: `${progress}%` }"></div>
    </div>
  </div>
</template>

<script>
import { onBeforeUnmount } from "vue";
import { useState } from "@/store";
import Logo from "@/components/Logo";

export default {
  components: {
    Logo
  },
  props: {
    progress: Number
  },

  setup() {
    const state = useState();

    onBeforeUnmount(() => {
      setTimeout(() => {
        if (!state.user) {
          state.router = {
            view: "Home",
            section: "Greeting"
          };
        } else {
          state.router = {
            view: "Select",
            section: "Mood"
          };
        }
      }, 500);
    });
  }
};
</script>

<style lang="scss" scoped>
#preloader {
  position: fixed;
  width: 100%;
  height: 100%;
  z-index: 1001;
  background: $background_yellow;
  display: flex;
  align-items: center;
  justify-content: center;
  color: $color_white;

  &._up {
    .preloader_progress {
      transition: opacity 0.5s ease-in-out 0.5s;
      opacity: 0;
    }
  }
}

.preloader_logo {
  position: relative;
  left: 0.7rem;
}

.preloader_progress {
  margin-top: 0.5rem;
  background-color: currentColor;
  height: 2px;
}
</style>

import { reactive, provide, inject } from "vue";
export const stateSymbol = Symbol("state");
export const createState = () =>
  reactive({
    system: {
      appReady: false,
      contentIsReady: false,
      isTouch: false,
      isDesktop: false,
      isAdaptive: false,
      isMobile: false,
      isIOS: false,
      width: 0,
      height: 0,
      centerX: 0,
      centerY: 0,
      ratio: 0,
      pixelRatio: 0,
      url: window.location.origin + "/",
      shareApiIsSupported: false
    },
    router: {
      view: null,
      section: null
    },
    user: null,
    contentIsWhite: false,
    THREE: {
      isReady: false,
      scene: "none",
      loadingProgress: [],
      loadingDone: false,
      moodsPos: [],
      selectedMood: null,
      selectIsEnable: false
    },
    genre: {
      selected: null
    },
    request: {
      mood: null,
      genre: null
    },
    response: null
  });
export const useState = () => inject(stateSymbol);
export const provideState = () => provide(stateSymbol, createState());

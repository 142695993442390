<template>
  <transition name="fade" appear>
    <Preloader :progress="loadingProgress" v-if="!state.THREE.loadingDone" />
  </transition>

  <div id="app_ready" v-if="state.system.appReady">
    <ThreeScene />

    <div id="app_content" :class="['container', { _white: isContentWhite }]">
      <AppHeader />
      <div id="main">
        <Content />
      </div>
    </div>

    <Viewport v-if="viewportBlock" />
  </div>
  <div id="lighthouse">
    Text to prevent lighthouse bug
  </div>
</template>

<script>
import { useState } from "@/store";
import { computed, onMounted, defineAsyncComponent, watch } from "vue";
import { isMobile, isMobileOnly, isIOS } from "mobile-device-detect";
import Preloader from "@/components/Preloader";
import AppHeader from "@/components/AppHeader";
import Viewport from "@/components/Viewport";

export default {
  components: {
    Preloader,
    ThreeScene: defineAsyncComponent(() =>
      import(/* webpackChunkName: "three" */ "@/components/ThreeScene.vue")
    ),
    AppHeader,
    Viewport,
    Content: defineAsyncComponent(() =>
      import(/* webpackChunkName: "content" */ "@/Content.vue")
    )
  },

  setup() {
    const state = useState();

    const setSize = () => {
      state.system.width = window.innerWidth;
      state.system.height = window.innerHeight;
      state.system.centerX = state.system.width / 2;
      state.system.centerY = state.system.height / 2;
      state.system.ratio = state.system.width / state.system.height;
    };

    const updateSize = () => {
      const vh = window.innerHeight * 0.01;
      document.documentElement.style.setProperty("--vh", `${vh}px`);

      setSize();
    };

    state.system.isDesktop = !isMobile;
    state.system.isAdaptive = isMobile;
    state.system.isMobile = isMobileOnly;
    state.system.isIOS = isIOS;
    state.system.pixelRatio = window.devicePixelRatio;
    if ("ontouchstart" in document.documentElement) {
      state.system.isTouch = true;
    }
    updateSize();
    if (navigator.share) {
      state.system.shareApiIsSupported = true;
    }

    const viewportBlock = computed(
      () =>
        (state.system.isMobile && state.system.ratio > 1) ||
        (state.system.isDesktop &&
          (state.system.width <= 1024 || state.system.height < 420))
    );

    const isContentWhite = computed(
      () =>
        (state.system.isAdaptive && state.contentIsWhite) ||
        (state.router.view === "Select" &&
          state.genre.selected !== null &&
          state.THREE.scene !== "genreApplied") ||
        state.THREE.scene === "filmLoadStarts"
    );

    const loadingProgress = computed(() => {
      let result = 0;
      const app = state.system.appReady ? 5 : 0,
        content = state.system.contentIsReady ? 5 : 0,
        three = state.THREE.isReady ? 15 : 0,
        models = (() => {
          let resultModels = 0;
          state.THREE.loadingProgress.forEach(element => {
            resultModels += element * 0.75;
          });
          resultModels =
            (resultModels / state.THREE.loadingProgress.length) * 100;
          resultModels = resultModels ? resultModels : 0;
          return resultModels;
        })();

      result = app + content + three + models;
      return result;
    });

    const progressWatcher = watch(
      () => loadingProgress.value >= 100,
      () => {
        state.THREE.scene = "construct";

        setTimeout(() => {
          state.THREE.loadingDone = true;
        }, 1500);

        progressWatcher();
      }
    );

    const instagramDetect = () => {
      const str = navigator.userAgent;
      const i = str.indexOf("Instagram");
      const url = window.location.href;

      if (i != -1 && url.indexOf("?") === -1) {
        setTimeout(() => {
          window.location.href = url + "?reload=true";
        }, 500);
      }
    };

    onMounted(() => {
      setTimeout(() => {
        state.system.appReady = true;
      }, 500);

      if (state.system.isIOS) {
        document.body.classList.add("ios");
      }

      instagramDetect();

      window.addEventListener("resize", () => {
        updateSize();
      });
    });

    return { state, viewportBlock, loadingProgress, isContentWhite };
  }
};
</script>

<style lang="scss">
@mixin font(
  $fontFamily: "",
  $fontName: "",
  $fontWeight: 400,
  $fontStyle: normal,
  $fontDisplay: swap
) {
  @font-face {
    font-family: $fontFamily;
    src: url("./assets/fonts/#{$fontName}.woff2") format("woff2"),
      url("./assets/fonts/#{$fontName}.woff") format("woff");
    font-style: $fontStyle;
    font-weight: $fontWeight;
    font-display: $fontDisplay;
  }
}

@include font("Montserrat", "Montserrat-Regular", $fontWeight: 400);
@include font("Montserrat", "Montserrat-Bold", $fontWeight: 700);
@include font(
  "Montserrat",
  "Montserrat-BoldItalic",
  $fontWeight: 700,
  $fontStyle: italic
);
@include font("Montserrat", "Montserrat-Black", $fontWeight: 900);
@include font("Practice", "PracticefontRegular", $fontWeight: 400);
@include font("ProximaNova", "ProximaNova-Black", $fontWeight: 900);
@include font("ProximaNova", "ProximaNova-Regular", $fontWeight: 400);

*,
*::after,
*::before {
  box-sizing: border-box;
  outline: none;
  -webkit-tap-highlight-color: transparent;
  user-select: none;
}

html,
body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  margin: 0;
  overflow: hidden;
}

body.ios {
  position: fixed;
  overflow: hidden;
  width: 100%;
  height: 100%;
}

html {
  font-size: 62.5%;
  background: #fff;
  height: 100vh;
  height: calc(var(--vh, 1vh) * 100);

  @media screen and (max-width: 767px) {
    font-size: calc(10px + 13 * (100vw - 375px) / 447);
  }

  @media screen and (min-width: 768px) and (max-width: 1023px) {
    font-size: calc(10px + 4 * (100vw - 768px) / 255);
  }

  @media (min-width: 1024px) and (max-width: 1360px) {
    font-size: calc(8px + 2 * (100vw - 1024px) / 336);
  }

  @media (min-width: 1024px) and (max-height: 800px) {
    font-size: calc(9px + 2 * (100vh - 800px) / 225);
  }

  @media (max-width: 768px) and (max-height: 800px) {
    font-size: calc(10px + 2 * (100vh - 800px) / 225);
  }
}

ul {
  margin: 0;
  padding-left: 0;
  list-style-type: none;
}

strong,
b {
  font-weight: 700;
}

a {
  color: inherit;
  text-decoration: none;
}

a,
button {
  cursor: pointer;
}

picture {
  img {
    display: block;
  }
}

canvas {
  display: block;
  position: fixed;
  width: 100%;
  height: 100%;
  z-index: -1;
  top: 0;
  left: 0;
}

body,
#app,
#app_ready,
#app_content {
  height: inherit;
}

#app {
  font-family: $mainFont;
  overflow: hidden;
  position: relative;
  font-size: 1.6rem;
}

#app_content {
  display: flex;
  flex-direction: column;

  &._white {
    #header,
    .whiteContent {
      color: $color_white !important;
    }
  }
}

.whiteContent {
  transition: color $transition_default;
}

#main {
  flex: 1;
}

.view,
section {
  height: 100%;
}

.h {
  font-family: $hFont;
  margin: 0;
  color: $color_navi;

  span {
    color: $color_blue;
  }
}

.h1 {
  font-size: 9.5rem;
  line-height: 9.5rem;

  span {
    display: block;
    font-size: 5.5rem;
  }
}

.h2 {
  font-size: 5.5rem;
  line-height: 5.5rem;
}

.h3 {
  font-size: 2.6rem;
  line-height: 2.6rem;
  font-weight: normal;
}

p {
  margin: 0;
  line-height: 1.8rem;
}

.section_header {
  margin-top: 7.3rem;

  .h2 {
    max-width: 46rem;
  }

  p {
    max-width: 40rem;
    margin-top: 3rem;
  }
}

@include --mobile-lg {
  .h1 {
    font-size: 3.6rem;
    line-height: 3.6rem;

    span {
      font-size: 3rem;
      line-height: 3rem;
    }
  }

  .h2 {
    font-size: 3rem;
    line-height: 3rem;
  }

  .h3 {
    font-size: 3rem;
    line-height: 3rem;
  }

  p {
    font-size: 1.4rem;
    line-height: 1.6rem;
  }

  .section_header {
    margin-top: 4.8rem;

    .h2 {
      font-size: 3.6rem;
      line-height: 3.6rem;
    }

    p {
      max-width: 27rem;
      margin-top: 2.6rem;
    }
  }
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.3s ease-in-out;
}

.fade-enter-active {
  transition-delay: 0.3s;
}

.fade-enter,
.fade-leave-active {
  opacity: 0;
}
</style>

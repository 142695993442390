<template>
  <header id="header">
    <a href="https://paraweb.me/" aria-label="Go to paraweb.me"><Logo /></a>
  </header>
</template>

<script>
import Logo from "@/components/Logo";

export default {
  components: {
    Logo
  }
};
</script>

<style lang="scss" scoped>
#header {
  margin-top: 6.5rem;
  display: flex;
  color: #050505;
}

@include --mobile-lg {
  #header {
    margin-top: 2rem;
  }
}
</style>
